@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'FuturaBT';
  src: url('../public/fonts/futurabi.ttf') format('truetype');
}

* {
  box-sizing: border-box;
}

body {
  font-family: fot-tsukuardgothic-std, sans-serif;
  color: #fff;
  line-height: 1.5;
  margin: 0;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

h1, h2, h3, h4, h5, h6, p {
  margin: 0;
}
img {
  max-width: 100%;
}
figure {
  margin: 0;
}
a {
  text-decoration: none;
}
button {
  font-family: fot-tsukuardgothic-std, sans-serif;
  border: none;
  outline: none;
}
input:focus-visible {
  outline: none;
}
.futurabt {
  font-family: 'FuturaBT';
}
.fs-0 {
  font-size: 0;
}
.center {
  text-align: center;
}
button {
  padding: 0;
}
.btn-box {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 60px;
  font-size: 18px;
  font-weight: bold;
  text-align: center;
  color: #fff;
  padding: 0 10px;
  margin: auto;
  border-radius: 18px;
  text-decoration: none;
  border: none;
  outline: none;
}
.btn {
  width: 100%;
  height: 40px;
  font-size: 16px;
  font-weight: bold;
  color: #e3e3e3;
}
.bg-blue {
  background-color: #0021b1;
}
.bg-blue-1 {
  background-color: #0b308f;
}
.bg-sky {
  background-color: #00b8e7;
}
.bg-sky-2 {
  background-color: #00b1a9;
}
.bg-green {
  background-color: #007d32;
}
.bg-danger {
  background-color: #e60027;
}
.bg-green-1 {
  background-color: #006835;
}
.bg-gray-1 {
  background-color: #bcbcbc;
}
.border-green-1 {
  border-color: #006835;
  border-style: solid;
}
.sky {
  color: #00b8e7;
}
.sky-1 {
  color: #00e6fb;
}
.sky-2 {
  color: #00b1a9;
}
.gray-1 {
  color: #a7a7a7;
}
.fs-0 {
  font-size: 0;
}
.fs-15 {
  font-size: 15px;
}
.f-24 {
  font-size: 24px !important;
}
.mb-25 {
  margin-bottom: 25px;
}
.form-label {
  position: relative;
  display: block;
  font-size: 18px;
  font-weight: bold;
  color: #fff;
  text-align: center;
  padding: 10px 0;
  margin-bottom: 14px;
  background-color: #007d32;
}
.form-label span.step {
  position: absolute;
  left: 15px;
  top: 50%;
  font-size: 14px;
  transform: translateY(-50%);
}
.btn-onepoint {
  font-size: 0;
  max-width: 45%;
}
.title {
  position: relative;
  font-size: 18px;
  font-weight: bold;
  text-align: center;
  z-index: 1;
}
.title span {
  position: absolute;
  width: 100%;
  left: 0;
  top: 50%;
  color: #00b1a9;
  font-family: 'FuturaBT';
  font-size: 36px;
  font-weight: bold;
  font-style: italic;
  transform: translateY(-50%);
  z-index: -1;
  text-transform: uppercase;
  letter-spacing: 4px;
}
.d-flex {
  display: flex;
}
.align-center {
  align-items: center;
}
.justify-center {
  justify-content: center;
}
.bold {
  font-weight: bold;
}
.fs-15 {
  font-size: 15px;
}
.fs-14 {
  font-size: 14px;
}
.menu-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  max-width: 768px;
  width: 100%;
  height: 100%;
  opacity: 0;
  margin: auto;
  visibility: hidden;
  transition: .3s all;
  z-index: 100;
}
.menu-overlay.show {
  opacity: 1;
  visibility: visible;
}
.menu-overlay .inner {
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 40px 20px 25px;
}
.menu-body {
  position: relative;
  padding: 35px 25px 20px;
  border-radius: 45px;
  background-color: #e3e3e3;
}
.menu-body hr {
  width: 85%;
  height: 1px;
  margin: 0;
  background-color: #00b1a9;
  border: none;
}
.menu-body .btn-close {
  position: absolute;
  top: 20px;
  right: 20px;
  width: 28px;
  height: 28px;
  padding: 0;
  background-color: transparent;
}
.menu-body .btn-logout {
  width: 100%;
  height: 35px;
  font-size: 16px;
  color: #e3e3e3;
  margin-top: 16px;
  background-color: #0b308f;
}
.blue {
  color: #0b308f;
}
.react-datepicker-wrapper {
  width: 100%;
  color: #000;
}
.level_1 {
  color: green;
}
.level_2 {
  color: orange;
}
.level_3 {
  color: red;
}

.pagination {
  display: flex;
  justify-content: center;
}
.pagination > li {
  min-width: 30px;
  height: 30px;
  text-align: center;
  border-radius: 4px;
  border: 1px solid #fff;
  margin: 0 6px;
}
.pagination > li a {
  display: block;
  color: #fff;
  font-size: 16px;
  font-weight: 500;
  line-height: 28px;
  cursor: pointer;
  padding: 0 2px;
}
.pagination > li.active {
  background-color: #fff;
}
.pagination > li.active a {
  color: #191919;
}
.pagination-list {
  margin-top: 40px;
}
.pagination-wishlist {
  padding: 30px 0 40px;
}
@media screen and (max-width: 768px) {
  .pagination > li {
    min-width: 26px;
    height: 26px;
    margin: 0 3px;
  }
  .pagination > li a {
    font-size: 14px;
    line-height: 24px;
  }
}